import React from 'react'
import { Link } from 'react-router-dom';
import mechanicalProducts from './Mechanical.json'
import "./ProductIndex.css"
import ProductCard from '../../components/ProductCard';
import mechanical from "../../images/mechanical.png";
import electronics from "../../images/electronics.png";
import energy from "../../images/energy.png";
import thermodynamics from "../../images/thermodynamics.png";
import physics from "../../images/physics.png";
import fluid from "../../images/fluid.png"
import electrical from "../../images/electrical.png"
import greyimage from '../../images/greyimage.png'
import Trainer from "../../images/trainer.png"
import { useLocation } from 'react-router-dom';
import "./Product.css"

export default function ProductIndex() {
  const location = useLocation();
  const pageName = location.pathname.substring(1);
  console.log(pageName)
  const imageMap = {
    'mechanical': mechanical,
    'energy': energy,
    'fluid': fluid,
    'physics':physics,
    'thermodynamics': thermodynamics,
    'electronics': electronics,
    'electrical': electrical
  };
  const imageName = imageMap[pageName];

  return (
    <div>
      <div >
            <img className='mechanical_cover' src={imageName}></img>
        </div>
        <div className='mechanical_section'>
          <div className='mechanical_heading'>
            <h5>{pageName} Products</h5>
          </div>

        </div>
      <div className='mechanical_index'>
      
      
        {Object.entries(mechanicalProducts[pageName]).map(([mechanicalId, {id, name, content, Url}]) =>
        {
            return(
              <>
              {pageName === "electrical" ? (
                <ProductCard id={id} name={name} content={content} imgUrl={Trainer} Url={Url} />
              ):(
                <ProductCard id={id} name={name} content={content} imgUrl={greyimage} Url={id} />
              )}
                
              </>
            )
        }
        )}
    </div>
    </div>
  )
}
