import React from 'react'
import "./AboutSection.css"

export default function AboutSection() {
  return (
    <div className='company-profile'>
   
        <div class="profile-content">
            <h2>Company Profile</h2>
            
            <h3>Training engineers for the past 10 years</h3>
            <p>Hawk Engineering Service is designing and manufacturing advanced engineering equipments for different organizations for the past 10 Years. We are passionate about empowering the next generation of engineers and scientists through our innovative equipment. As a leading manufacturer in the industry, we are committed to providing high-quality, hands-on learning tools that inspire curiosity, foster creativity, and cultivate critical thinking skills. Our products combine cutting-edge technology, robust construction, and user-friendly interfaces, enabling students to explore the realms of science, technology, engineering, and mathematics (STEM) with confidence.</p>
           
            <a href="#" class="cta-button">ABOUT US</a>
        </div>
    

</div>
  )
}
