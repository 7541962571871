import React from 'react'
import { useLocation } from 'react-router-dom';


export default function ProductDetails() {

  return (
    <div>MechanicalDetails</div>
  )
}
