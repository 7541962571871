import React from 'react'
import { useSelector } from 'react-redux';
import data from './products/Mechanical.json'

export default function SearchResultPage() {
    const searchQuery = useSelector((state) => state.search.searchQuery);
    const contentArray = Object.values(data);
    const searchResults = contentArray.filter((item) => {
        const name = item.name ? item.name.toLowerCase() : ''; // Add null check
        const content = item.content ? item.content.toLowerCase() : ''; // Add null check
    
        return (
            name.includes(searchQuery.toLowerCase()) ||
            content.includes(searchQuery.toLowerCase())
        );
      });
      console.log(searchResults)
  return (
    <div>
      <h2>Search Results for "{searchQuery}":</h2>
      {searchResults.map((result) => (
        <div key={result.id}>
          <h3>{result.name}</h3>
          <p>{result.content}</p>
        </div>
      ))}
    </div>
  )
}
