import "./Footer.css"
import logoWhite from "../images/logowhite.png"
import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <div className="footer_container">
        <img className="footer_logo" src={logoWhite}></img>
        <hr className="footer_breaker"></hr>
    
        
        <div className="footer_navigation">
        <ul className="footer-links">
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Terms of Service</a></li>
                <li><a href="#">Contact</a></li>
                <li><a href="#">Sitemap</a></li>
            </ul>
            <p>&copy; 2024 Hawk Engieering Services. All rights reserved.</p>
            <ul class="social-media">
                <li><a href="#"><img src="facebook.png" alt="Facebook"></img></a></li>
                <li><a href="#"><img src="twitter.png" alt="Twitter"></img></a></li>
                <li><a href="#"><img src="linkedin.png" alt="LinkedIn"></img></a></li>
                <li><a href="#"><img src="instagram.png" alt="Instagram"></img></a></li>
            </ul>
        </div>
        </div>
   
  )
}
