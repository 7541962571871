import React from 'react'
import "./Categories.css"
import Thermodynamics from "../images/categories/thermodynamics.png"
import Mechatronics from "../images/categories/mechatronics.png"
import Energy from "../images/categories/energy.png"
import Physics from "../images/categories/physics.png"
import Mechanics from "../images/categories/mechanics.png"
import Fluid from "../images/categories/fluid.png"
import Electrical from "../images/categories/electrical.png"
import { Link } from 'react-router-dom'


export default function Categories() {
  return (
    <div className="categories-carousel">
        <Link to='thermodynamics'>
        <img src={Thermodynamics} alt="Image 1" className="categories-item"></img>
        </Link>
        <Link to='electrical'>
        <img src={Electrical} alt="Image 3" className="categories-item"></img>
        </Link>
        <Link to='energy'>
        <img src={Energy} alt="Image 4" className="categories-item"></img>
        </Link>
        <Link to='physics'>
        <img src={Physics} alt="Image 5" className="categories-item"></img>
        </Link>
        <Link to='mechanics'>
        <img src={Mechanics} alt="Image 6" className="categories-item"></img>
        </Link>
        <Link to='fluid'>
        <img src={Fluid} alt="Image 7" className="categories-item"></img>
        </Link>
      
    </div>
  )
}

 