import React from 'react'
import Header from '../components/Header';
import ProductShowcase from '../components/ProductShowcase';
import AboutSection from '../components/AboutSection';
import ServiceSection from '../components/ServiceSection';
import NewsSection from '../components/NewsSection';
import Partner from '../components/Partner';
import Categories from '../components/Categories';
import ContactSection from '../components/ContactSection';

function Home() {
    return (
        <div>
            <Header/>
            <Categories/>
            <AboutSection/>
            <ProductShowcase/>
            <ServiceSection/>
            {/* <NewsSection/> */}
            <Partner/>
            <ContactSection/>
        </div>
    )
}

export default Home;