import React from 'react'
import "./ProductCard.css"
import greyimage from '../images/greyimage.png'
import { Link } from 'react-router-dom'

export default function ProductCard({id,name,content,imgUrl,Url}) {
  return (
    <div className='product_card'>
       <div className='prodcut_caption'>
        <img className='product_image' src={imgUrl}>
        </img>
        <div className='product_description'>
            <h4>
              {name}
            </h4>
            <p>
              {content}
            </p>
            <a href={Url} target="_blank" rel="noopener noreferrer">More Details</a>
            
        </div>

       </div>
       <div>

       </div>
    </div>
  )
}
