import React from 'react'



export default function Admin() {

  return (
    <div>
    <h1>
        ADMIN
    </h1>
    
  </div>
  )
}
