import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../images/logo.png"
import { useDispatch, useSelector } from 'react-redux';
import { setSearchQuery } from './search/searchAction'
import { useNavigate } from 'react-router-dom';
import './NavBar.css'

export default function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search.searchQuery);

  const handleInputSearch = (event) => {
    const query = event.target.value;
    dispatch(setSearchQuery(query));
    
  };
  const handleSearch = (event) => {
    navigate("/search");
    
  };


  return (
    <Navbar className="justify-content-center"  bg="light" variant='light' expand="lg">
    <Container fluid>
      <Navbar.Brand href="#">
      <img
              src={logo}
              width="50"
              height="50"
              className="d-inline-block align-top"
              alt="Hawk Company logo"
            />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '100px', textDecoration: 'none',  }}
          navbarScroll
        >
          <Nav.Link href="/">Home</Nav.Link>
          <NavDropdown title="Products" id="navbarScrollingDropdown">
          <NavDropdown.Item><Link className='nav-link' to='electrical'>Electrical</Link></NavDropdown.Item>
            <NavDropdown.Item ><Link className='nav-link' to='energy'>Energy</Link></NavDropdown.Item>
            <NavDropdown.Item ><Link className='nav-link' to='fluid'>Fluid Mechanics</Link></NavDropdown.Item>
            <NavDropdown.Item ><Link className='nav-link' to='mechanical'>Mechanical</Link></NavDropdown.Item>
            <NavDropdown.Item ><Link className='nav-link' to='physics'>Physics</Link></NavDropdown.Item>
            <NavDropdown.Item ><Link className='nav-link' to='thermodynamics'>Thermodynamics</Link></NavDropdown.Item>
            <NavDropdown.Item><Link className='nav-link' to='electronics'>Electronics</Link></NavDropdown.Item>
            {/* <NavDropdown.Divider />
            <NavDropdown.Item href="#action5">
              See All
            </NavDropdown.Item> */}
          </NavDropdown>
          {/* <Nav.Link href="news">News</Nav.Link> */}
          <Nav.Link ><Link className='nav-link' to='services'>Services</Link></Nav.Link>
          <Nav.Link ><Link className='nav-link' to='about'>About</Link></Nav.Link>
          <Nav.Link ><Link className='nav-link' to='contact'>Contact Us</Link></Nav.Link>
         
        </Nav>
        {/* <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={handleInputSearch}
            className="me-2"
            aria-label="Search"
          />
          <Button variant="primary" onClick={handleSearch}>Search</Button>
        </Form> */}
         
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}
