import React from 'react'
import "./ContactSection.css"



export default function ContactSection() {
  return (
    <div class="contact-section">
        <h2>Any questions? We are happy to help...</h2>
        <div class="contact-item">
            <div class="icon"><img src="phone-icon.png" alt="Phone Icon"></img></div>
            <div class="info"></div>
        </div>
        <div class="contact-item">
            <div class="icon"><img src="email-icon.png" alt="Email Icon"></img></div>
            <div class="info"><a href="mailto:info@hawkengg.com">info@hawkengg.com</a></div>
        </div>
        <div class="contact-item">
            <div class="icon"><img src="newsletter-icon.png" alt="Newsletter Icon"></img></div>
            <div class="info"><a href="#">Newsletter</a></div>
        </div>
    </div>
  )
}

