import Navbar from 'react-bootstrap/Navbar';
import './CompanyName.css'


export default function CompanyName() {
  return (
    <Navbar className="justify-content-center"  bg="black" variant='light' expand="lg">
       <div className="company_name">
        HAWK
        ENGINEERING
        SERVICES
       </div>
  </Navbar>
  )
}
