import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import Home from './pages/home';
import About from './pages/About';
import NavBar from './components/NavBar';
import CompanyName from './components/CompanyName';
import FooterBottom from './components/FooterBottom';
import Footer from './components/Footer';
import store from './components/search/store'
import { Provider } from 'react-redux';
import SearchResultPage from './pages/searchResultPage';
import Admin from './pages/Admin';
import Product from './pages/products/Product';
import ProductIndex from './pages/products/ProductIndex';
import ProductDetails from './pages/products/ProductDetails';


function App() {
  return (
    <Router>
      <Provider store={store}>
      <CompanyName/>
      <NavBar/>
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='about' element={<About/>}></Route>
      {/* mechanical */}
      <Route path='mechanical' element={<Product/>}>
        <Route path='/' element={<ProductIndex/>}></Route>
        <Route path=':mechanicalId' element={<ProductDetails/>}></Route>
       </Route>
       {/* physics */}
       <Route path='/physics' element={<Product/>}>
        <Route path='/' element={<ProductIndex/>}></Route>
        <Route path=':physicsId' element={<ProductDetails/>}></Route>
       </Route>
       {/* Energy */}
       <Route path='energy' element={<Product/>}>
        <Route path='/' element={<ProductIndex/>}></Route>
        <Route path=':energyId' element={<ProductDetails/>}></Route>
       </Route>
       {/* Thermodynamics */}
       <Route path='thermodynamics' element={<Product/>}>
        <Route path='/' element={<ProductIndex/>}></Route>
        <Route path=':thermodynamicsId' element={<ProductDetails/>}></Route>
       </Route>
       {/* Fluid Mechanics */}
       <Route path='fluid' element={<Product/>}>
        <Route path='/' element={<ProductIndex/>}></Route>
        <Route path=':fluidId' element={<ProductDetails/>}></Route>
       </Route>
       {/* Electronics */}
       <Route path='electronics' element={<Product/>}>
        <Route path='/' element={<ProductIndex/>}></Route>
        <Route path=':electronicsId' element={<ProductDetails/>}></Route>
       </Route>
       {/* Electrical */}
       <Route path='electrical' element={<Product/>}>
        <Route path='/' element={<ProductIndex/>}></Route>
        <Route path=':electricalId' element={<ProductDetails/>}></Route>
       </Route>
       <Route path='admin' element={<Admin/>}></Route>
      <Route path='search' element={<SearchResultPage/>}></Route>
       {/* <Route path='contact' element={<Contact />}></Route>
      <Route path ='*' element={<NotFound />}></Route>  */}
    </Routes>
    <Footer/>
    
    </Provider>
  </Router>
  );
}

export default App;
