import React from 'react'
import { Outlet } from 'react-router-dom'
import mechanical from "../../images/mechanical.png"
import "./Product.css"


export default function Product() {

  
  return (
    <div>
        
        <Outlet/>
    </div>
  )
}
