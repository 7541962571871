import React from 'react'
import "./Partner.css"
import Slider from "react-slick";
import client1 from '../images/client/client1.png'
import client2 from '../images/client/client2.png'
import client3 from '../images/client/client3.png'
import client4 from '../images/client/client4.png'
import client5 from '../images/client/client5.png'
import client6 from '../images/client/client6.png'
import client7 from '../images/client/client7.png'

import Image from 'react-bootstrap/Image'

export default function Partner() {
    const settings = {
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1500,
        cssEase: "linear",
        centerMode: true,
        centerPadding: "50px",
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
        
    }
  return (
    <div className='partner_container'>
    <h1 className='partner_container_heading'>Our Clients</h1>
    <hr className='heading_breaker'></hr>
    <div className='partner_slider'>
    <Slider {...settings}>
            <img src={client1}></img>
            <img src={client2}></img>
            <img src={client3}></img>
            <img src={client4}></img>
            <img src={client5}></img>
            <img src={client6}></img>
            <img src={client7}></img>
            
        </Slider>
      </div>
    </div>

  )
}
