import "./Header.css"
import Carousel from 'react-bootstrap/Carousel';
import StartSlider from '../images/slider1.png'
import MidSlider from '../images/slider2.png'
import EndSlider from '../images/slider3.png'



export default function Header() {
  return (
    <Carousel>
    <Carousel.Item interval={5000}>
      <img
        className="d-block w-100 header_image"
        src={StartSlider}
        alt="First slide"
      />
      <Carousel.Caption className="header_caption">
        <h1>Designing and developing innovative solutions for your scientific problems </h1>
        
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item interval={5000}>
      <img
        className="d-block w-100 header_image"
        src={MidSlider}
        alt="Second slide"
      />
      <Carousel.Caption className="header_caption">
        <h1>Accelerating technology and innovation</h1>
       
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100 header_image"
        src={EndSlider}
        alt="Third slide"
      />
      <Carousel.Caption className="header_caption">
        <h1>Empowering the minds of tommorow with our innovation and creations</h1>
        
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
  )
}
