import "./ProductShowcase.css"
import ShowcaseCard from "./ShowcaseCard"
import Physics from "../images/product/physics.png"
import Electronics from "../images/product/electronics.png"
import Mechanical from "../images/product/mechanical.png"
import Fluid from "../images/product/fluid.png"
import Thermodynamics from "../images/product/thermodynamics.png"
import Energy from "../images/product/energy.png"

export default function ProductShowcase() {
  return (
    <div className="productShowcase_container">
        <h1 className="productShowcase_heading">Our Products</h1>
        <hr className="heading_breaker"></hr>
        <div className="ShowcaseCard_container">
        <ShowcaseCard imgLink={Electronics} Name='Electronics' Blurb='' category='/electronics'/>
        <ShowcaseCard imgLink={Energy} Name='Energy' Blurb='' category='/energy'/>
        <ShowcaseCard imgLink={Fluid} Name='Fluid Mechanic' Blurb='' category='/fluid'/>
        <ShowcaseCard imgLink={Mechanical} Name='Mechanics' Blurb='' category='/mechanical'/>
        <ShowcaseCard imgLink={Physics} Name='Physics' Blurb='' category='/physics'/>
        <ShowcaseCard imgLink={Thermodynamics} Name='Thermodynamics' Blurb='' category='/thermodynamics'/>
       </div>
     

    </div>
  )
}
