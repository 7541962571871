import "./ShowcaseCard.css"

import { Link } from 'react-router-dom'


export default function ShowcaseCard({imgLink, Name, Blurb,category}) {
   
  return (
        <div className='Card card-Slide-up'>
            <img src={imgLink} alt='thermal product'/>
            <div className='cardCaption' >
                <h2 className='card-title'>{Name}</h2>
                <p className='card-content'>
                    {Blurb}
                </p>
                <p><Link to={category}>More Details</Link></p>    
            </div>
        </div>
  )
}
