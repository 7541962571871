import React, {useState} from 'react'
import "./ServiceSection.css"
import Slider from "react-slick";
import Design from "../images/services/design.png"
import Research from "../images/services/research.png"
import Tool from "../images/services/tool.png"
import Consult from "../images/services/consult.png"
import Training from "../images/services/training.png"
import Maintainence from "../images/services/maintainence.png"
import Categories from './Categories';




const services = [
  {
    title: 'Custom Equipment Design',
    subtitle: 'Offering tailor-made engineering equipment solutions to meet the specific needs of different organizations.',
    image: Design,
  },
  {
    title: 'Collaborative Research Projects',
    subtitle: 'Partnering with academic institutions and research organizations on collaborative research projects that involve the use of advanced engineering equipment.',
    image: Research,
    
  },
  {
    title: 'Equipment Maintenance',
    subtitle: 'Providing regular maintenance services to ensure the longevity and optimal performance of the equipment supplied by the company.',
    image: Maintainence,
  },
  {
    title: 'Consulting Services',
    subtitle: 'Offering expert consultation to organizations seeking advice on the selection, implementation, and optimization of engineering equipment for their operations.',
    image: Consult,
  },
  {
    title: 'Training Workshops',
    subtitle: 'Conducting training workshops for engineers, scientists, and educators on how to effectively use and integrate the advanced equipment into their projects and curricula.',
    image: Training,
  },
  {
    title: 'Online Learning Platform',
    subtitle: 'Creating an online platform with interactive tutorials, resources, and virtual simulations to enhance the educational value of our equipment.',
    image: Tool,
  }
];

export default function ServiceSection() {
  const [selectedProduct, setSelectedProduct] = useState(services[0]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    autoplay:true,
    autoplaySpeed: 1500,
    centerPadding: '0px',
  };
  return (
    <div>
    <div className="service-container">
      <h1 className='service_container_heading'>Services</h1>
      <hr className='heading_breaker'></hr>
        <div className='main-service'>
        <div className='service-content'>
          <div className="title">{selectedProduct.title}</div>
          <div className="subtitle">{selectedProduct.subtitle}</div>
        </div>
        {/* <img id="main-image" src={selectedProduct.image} alt={selectedProduct.title}/> */}
        </div>
      
      
    </div>
    <div className="categories-carousel">
          {services.map((service, index) => (
           
            <img key={index} onClick={() => setSelectedProduct(service)} src={service.image} alt="Image 1" className="categories-item"></img>
           ))}
      </div>
    </div>
    
  )
}
